import $ from "jquery";
import * as helpers from "./helpers.js";
import * as clicks from "./clicks.js";
import * as sliders from "./sliders.js";
import * as inviews from "./inviews.js";
//import modals from "van11y-accessible-modal-window-aria";

$(document).ready(function() {
  var header = $("header");
  document.createElement("main");
  helpers.checkBrowsers();

  if ($(".scrollbar-wrapper").length) {
    $(".scrollbar-wrapper").each(function() {
      scrollers.push(
        new MiniBar(this, {
          // or progress
          barType: "default",

          // min size
          minBarSize: 10,

          // always shows scrollbars
          alwaysShowBars: true,

          // enables horizontal/vertical scrollbars
          scrollX: false,
          scrollY: true,

          // shows nav buttons
          navButtons: false,

          // scroll amount in pixels
          scrollAmount: 10,

          // MutationObserver API
          mutationObserver: {
            attributes: false,
            childList: true,
            subtree: true
          }
        })
      );
    });
  }

  if ($("body").hasClass("is_IE")) {
    setTimeout(function() {
      scrollers.forEach(function(elm) {
        elm.destroy();
        elm.init();
      });
      //alert("s");
    }, 100);
  }

  var submenu_width = 0;
  $.each($(".menu > li"), function() {
    submenu_width = submenu_width + $(this).outerWidth();
  });

  $(".submenu-block.links").css("max-width", submenu_width);

  var timeout = false;

  function setSubmenuSize() {
    submenu_width = 0;
    $.each($(".menu > li"), function() {
      submenu_width = submenu_width + $(this).outerWidth();
    });

    $(".submenu-block.links").css("max-width", submenu_width);
  }

  // window.resize event listener
  window.addEventListener("resize", function() {
    // clear the timeout
    clearTimeout(timeout);
    // start timing for event "completion"
    timeout = setTimeout(setSubmenuSize, 350);
  });

  setSubmenuSize();
});
