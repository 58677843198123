import $ from "jquery";
import "slick-carousel";
import * as helpers from "./helpers.js";

$(".b-carousel-high,.b-carousel-lastest-slider").on("init", function(
  event,
  slick
) {
  $(this).addClass("visible");
});

$(".b-carousel-high").slick({
  infinite: true,
  speed: 1200,
  dots: true,
  arrows: false,
  fade: true,
  autoplay: true,
  autoplaySpeed: 5000,
  pauseOnHover: true
});

$(".gallery-slider-single-v1").slick({
  infinite: true,
  slidesToShow: 3,
  autoplay: false,
  speed: 600,
  //variableWidth: true,
  responsive: [
    {
      breakpoint: 1024,
      settings: {
        slidesToShow: 2
      }
    },
    {
      breakpoint: 600,
      settings: {
        slidesToShow: 1
      }
    }
  ]
});

$(".gallery-slider-single-v2").slick({
  infinite: false,
  autoplay: false,
  fade: true,
  speed: 600,
  dots: true,
  arrows: false
});

$(".b-carousel-lastest-slider").slick({
  infinite: false,
  autoplay: false,
  speed: 600,
  dots: true,
  slidesToShow: 4,
  responsive: [
    {
      breakpoint: 1024,
      settings: {
        slidesToShow: 2
      }
    },
    {
      breakpoint: 600,
      settings: {
        slidesToShow: 1
      }
    }
  ]
});

$(".b-carousel-event-slider").slick({
  infinite: false,
  autoplay: false,
  speed: 600,
  adaptiveHeight: true
});

$(".b-carousel-basic-slider").slick({
  infinite: false,
  autoplay: false,
  fade: true,
  adaptiveHeight: true,
  speed: 600
});

$(".b-timeline-item-wrapper").slick({
  infinite: false,
  arrows: true,
  speed: 600,
  adaptiveHeight: true,
  asNavFor: ".b-timeline-dates"
});

$(".b-carousel-datas-slider").slick({
  infinite: false,
  arrows: false,
  dots: true,
  speed: 600,
  centerPadding: "60px",
  centerMode: true,
  variableWidth: true
});

$(".b-timeline-dates").slick({
  slidesToShow: 9,
  infinite: false,
  arrows: false,
  speed: 600,
  asNavFor: ".b-timeline-item-wrapper",
  focusOnSelect: true,
  responsive: [
    {
      breakpoint: 767,
      settings: {
        slidesToShow: 5
      }
    }
  ]
});
