import $ from "jquery";
import * as helpers from "./helpers.js";
import "slick-carousel";
import { setTimeout } from "timers";

$(document).ready(function() {
  $.fn.dc_accordion = function() {
    var accordion = $(this);
    var titles = $(accordion).find(".b-toggles-title");
    var interval;

    titles.each(function() {
      $(this).on("click", function(event) {
        event.preventDefault();
        event.stopPropagation();

        clearInterval(interval);

        var is_closing = 0;
        var $this = $(this);
        var destiny_anchor = $this.attr("data-anchor-elm");

        if ($this.hasClass("active")) {
          $this
            .removeClass("active")
            .next()
            .slideUp();

          $(".toggle-slider-slide,.loader").removeClass("is_visible");

          $(".toggle-slider")
            .each(function() {
              $(this)
                .find(".toggle-slider-slide")
                .first()
                .addClass("is_visible");
            })

            .first()
            .addClass("is_visible");
          is_closing = 1;
        } else {
          var offset = Math.floor(get_offset($this));
          var active_elm = $(".b-toggles-title.active").length;
          var actual_offset = Math.floor(document.documentElement.scrollTop);

          if (actual_offset != offset) {
            if ($("body").hasClass("toggles-initialized")) {
              $("html, body").animate(
                {
                  scrollTop: offset
                },
                {
                  duration: 200,
                  complete: function() {
                    if (active_elm) {
                      active_elm = $(".b-toggles-title.active");
                      active_elm
                        .removeClass("active")
                        .next()
                        .hide(0, function() {
                          window.scrollTo(0, get_offset($this));
                          setTimeout(function() {
                            $this
                              .addClass("active")
                              .next()
                              .slideDown();
                          }, 1);
                        });
                    } else {
                      $this
                        .addClass("active")
                        .next()
                        .slideDown();
                    }
                  }
                }
              );
            } else {
              $this
                .addClass("active")
                .next()
                .slideDown();
            }
          } else {
            $this
              .addClass("active")
              .next()
              .slideDown();
          }
        }

        if ($this.next().find(".toggle-slider").length && !is_closing) {
          var slider = $this.next();
          slider.find(".loader").addClass("is_visible");
          clearInterval(interval);
          interval = setInterval(function() {
            var actual = slider.find(".toggle-slider-slide.is_visible");
            slider.find(".loader").removeClass("is_visible");
            actual.removeClass("is_visible");
            if (actual.next(".toggle-slider-slide").length) {
              actual.next().addClass("is_visible");
              slider.find(".loader").addClass("is_visible");
            } else {
              slider
                .find(".toggle-slider-slide")
                .first()
                .addClass("is_visible");
              slider.find(".loader").addClass("is_visible");
            }
          }, 6000);
        }

        return false;
      });
    });

    $(".b-toggles").each(function() {
      $(this)
        .find(".b-toggles-item")
        .first()
        .find(".b-toggles-title")
        .click();
    });
    setTimeout(function() {
      $("body").addClass("toggles-initialized");
    }, 1000);
  };

  $('a[href="#"]').click(function(e) {
    e.preventDefault();
  });

  $("span.menu-item-top").on("click", function() {
    if ($(window).width() < 900) {
      $(".rs-second-level").removeClass("rs-second-level");
      if (
        $(this)
          .parent()
          .hasClass("rs-first-level")
      ) {
        $(".megamenu-parent").removeClass("rs-first-level");
      } else {
        $(".megamenu-parent").removeClass("rs-first-level");
        $(this)
          .parent()
          .addClass("rs-first-level");
      }
    }
  });

  $("span.level-one-link").on("click", function() {
    if ($(window).width() < 900) {
      if ($(this).hasClass("rs-second-level")) {
        $(".rs-second-level").removeClass("rs-second-level");
      } else {
        $(".rs-second-level").removeClass("rs-second-level");
        $(this).addClass("rs-second-level");
      }
    }
  });

  $(".modal-trigger").on("click", function() {
    var iframe = $(".simple-animated-modal").find("iframe");

    if (!iframe.hasClass("complete")) {
      iframe.attr("src", iframe.attr("data-src"));
      iframe.addClass("complete");
    }
  });

  $(document).keypress(function(e) {
    if (e.which == 13 && $("body").find(".simple-animated2-modal").length) {
      ajaxFilter();
    }
  });

  $("body").on("click", "#search-btn", function(e) {
    ajaxFilter();
  });

  function ajaxFilter() {
    var transition = "fade"; // 'slide' | 'fade' | null
    var speed = "400"; //in milliseconds
    var search = $("#search-input").val();
    $(".block-loader").addClass("active");
    $(".alm-btn-wrap .alm-load-more-btn").removeClass("visible");
    $(".search-btn").css("pointerEvents", "none");
    var obj = {};
    obj["post-type"] = "post, page";
    obj["target"] = "search-panel";
    obj["search"] = search === undefined ? "" : search;
    obj["posts_per_page"] = -1;
    obj["pause"] = 0;

    if (search) {
      ajaxloadmore.filter(transition, speed, obj);
    }
  }

  $("body").on("click", ".simple-animated-modal-overlay", function() {
    setTimeout(function() {
      var iframe = $(".b-highs-item").find("iframe");
      iframe.attr("src", iframe.attr("src", ""));
      iframe.removeClass("complete");
    }, 50);
    $(".js-modal-close").click();
  });

  $(".panels-wrapper-close").on("click", function() {
    $(".magic-wrapper,.panels-panel").removeClass("active");
  });

  $(".magic-anchor").on("click", function() {
    $(".magic-anchor").removeClass("active");
    var destiny_anchor = $(this)
      .addClass("active")
      .attr("data-anchor");
    var destiny_elm = $("*[data-anchor-elm=" + destiny_anchor + "]");

    $("html, body").animate(
      {
        scrollTop: get_offset(destiny_elm)
      },
      Math.abs(window.scrollY - $(destiny_elm).offset().top) * 0.5
    );
  });

  function get_offset(elm) {
    var wpadminbar = 0;
    var anchor_menu = 0;

    if ($("#wpadminbar").length) {
      wpadminbar = $("#wpadminbar").outerHeight();
    }

    if ($(".b-anchor-menu").length) {
      anchor_menu = $(".b-anchor-menu").outerHeight();
    }

    return (
      $(elm).offset().top -
      anchor_menu -
      $(".header").outerHeight() -
      wpadminbar
    );
  }

  $(".shared-block-item").on("click", function(event) {
    event.preventDefault();
    event.stopPropagation();
    var a = document.createElement("a");
    a.target = "_blank";
    a.href = $(this).attr("data-href");
    a.click();
  });

  $(".header-nav-trigger").on("click", function(event) {
    event.preventDefault();
    event.stopPropagation();
    $(".header").toggleClass("active");
  });

  $(".cookies-btn").click(function(e) {
    e.preventDefault();
    helpers.setCookie("stanpa_cookies_verify", "1");
    $(".cookies-banner").addClass("go-out");
    setTimeout(() => {
      $(".cookies-banner").remove();
    }, 800);
  });

  if ($(".b-toggles").length) {
    var accordions = $(".b-toggles");
    accordions.each(function() {
      $(this).dc_accordion();
    });
  }
});
