import $ from "jquery";
//import objectFitImages from 'object-fit-images';

export function checkBrowsers() {
  var userAgent, ieReg, ie;
  userAgent = window.navigator.userAgent;
  ieReg = /msie|Trident.*rv[ :]*11\./gi;
  ie = ieReg.test(userAgent);

  if (ie) {
    document.createElement("main");
    $("body").addClass("ie");
    $(".object-fit-container").each(function() {
      var $container = $(this),
        imgUrl = $container.find("img").prop("src");
      if (imgUrl) {
        $container
          .css("backgroundImage", "url(" + imgUrl + ")")
          .addClass("custom-object-fit");
      }
    });
  }

  if ($("body").hasClass("is_edge")) {
    //document.body.innerHTML = document.body.innerHTML.replace(/\u2028/g, '');
  }
}

export function seeLog() {
  //console.log("yeah");
}

export function pad2(number) {
  return (number < 10 ? "0" : "") + number;
}

export function is_touch_device() {
  var prefixes = " -webkit- -moz- -o- -ms- ".split(" ");
  var mq = function(query) {
    return window.matchMedia(query).matches;
  };

  if (
    "ontouchstart" in window ||
    (window.DocumentTouch && document instanceof DocumentTouch)
  ) {
    return true;
  }

  // include the 'heartz' as a way to have a non matching MQ to help terminate the join
  // https://git.io/vznFH
  var query = ["(", prefixes.join("touch-enabled),("), "heartz", ")"].join("");
  return mq(query);
}

export function addBlacklistClass() {
  //console.log("black");

  $("a").each(function() {
    if (
      this.href.indexOf("/wp-admin/") !== -1 ||
      this.href.indexOf("/wp-login.php") !== -1
    ) {
      $(this)
        .addClass("no-barba")
        .addClass("wp-link");
    }
  });
}

export function debounce(func, wait, immediate) {
  var timeout;
  return function() {
    var context = this,
      args = arguments;
    var later = function() {
      timeout = null;
      if (!immediate) func.apply(context, args);
    };
    var callNow = immediate && !timeout;
    clearTimeout(timeout);
    timeout = setTimeout(later, wait);
    if (callNow) func.apply(context, args);
  };
}

export function throttle(func, limit) {
  let inThrottle;
  return function() {
    const args = arguments;
    const context = this;
    if (!inThrottle) {
      func.apply(context, args);
      inThrottle = true;
      setTimeout(() => (inThrottle = false), limit);
    }
  };
}

export function getUrlParameter(sParam) {
  var sPageURL = window.location.search.substring(1),
    sURLVariables = sPageURL.split("&"),
    sParameterName,
    i;

  for (i = 0; i < sURLVariables.length; i++) {
    sParameterName = sURLVariables[i].split("=");

    if (sParameterName[0] === sParam) {
      return sParameterName[1] === undefined
        ? true
        : decodeURIComponent(sParameterName[1]);
    }
  }
}

export function setCookie(key, value) {
  var expires = new Date();
  expires.setTime(expires.getTime() + 7 * 24 * 60 * 60 * 1000);
  document.cookie = key + "=" + value + ";expires=" + expires.toUTCString();
}

export function getCookie(key) {
  var keyValue = document.cookie.match("(^|;) ?" + key + "=([^;]*)(;|$)");
  return keyValue ? keyValue[2] : null;
}
