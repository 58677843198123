import $ from "jquery";
import inView from "in-view";
import anime from "animejs";
/* import CountUp from "countup.js"; */
import { CountUp } from "../dist/countUp.js";

inView.threshold(0);
inView.offset(50);

inView(".block-high-item").on("enter", function(el) {
  $(el).addClass("active");
  if ($("body").hasClass("is_IE")) {
    setTimeout(function() {
      scrollers.forEach(function(elm) {
        elm.update();
      });
    }, 1100);
  }
});

inView(".b-elm:not(.no-inview)").on("enter", function(el) {
  $(el).addClass("in-view-complete");
  if ($("body").hasClass("is_IE")) {
    setTimeout(function() {
      scrollers.forEach(function(elm) {
        elm.update();
      });
    }, 1100);
  }
});

inView(".animate-number").on("enter", function(el) {
  if ($("body").hasClass("is_IE")) {
    setTimeout(function() {
      scrollers.forEach(function(elm) {
        elm.update();
      });
    }, 1100);
  }
  if (!$(el).hasClass("animated")) {
    $(el).addClass("animated");
    var countUp = new CountUp(el, $(el).attr("data-number"));
    countUp.start();
  }
});
